import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/pagination/customisation",
  "title": "Pagination - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--active-page-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the active page background. Use black, white or any of the brand colours.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Pagination theme variables]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const [pageIndex, setPageIndex] = useState(0);
    const theme = {
      '--active-page-background-color': 'var(--color-bus)',
    };
    return (
      <Pagination
        language="en"
        onChange={(event, index) => {
          event.preventDefault();
          setPageIndex(index);
        }}
        pageCount={9}
        pageHref={() => '#'}
        pageIndex={pageIndex}
        paginationAriaLabel="Pagination 1"
        siblingCount={9}
        theme={theme}
      />
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<style scoped>
  .custom-active-page-background-color {
    --active-page-background-color: var(--color-bus);
  }
</style>

<div class="hds-pagination-container">
  <nav
    class="hds-pagination custom-active-page-background-color"
    role="navigation"
    aria-label="Pagination"
    data-next="Next"
    style="--active-page-background-color: var(--color-bus);"
  >
    <button
      type="button"
      disabled
      class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-prev"
    >
      <span aria-hidden="true" class="hds-icon hds-icon--angle-left"></span>
      <span class="hds-button__label">Previous</span>
    </button>
    <ul class="hds-pagination__pages">
      <li>
        <a
          class="hds-pagination__item-link hds-pagination__item-link--active"
          href="#"
          title="Current page"
          aria-label="Page 1"
          aria-current="page"
        >
          1
        </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 2" title="Go to page 2"> 2 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 3" title="Go to page 3"> 3 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 4" title="Go to page 4"> 4 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 5" title="Go to page 5"> 5 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 6" title="Go to page 6"> 6 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 7" title="Go to page 7"> 7 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 8" title="Go to page 8"> 8 </a>
      </li>
      <li>
        <a class="hds-pagination__item-link" href="#" aria-label="Page 9" title="Go to page 9"> 9 </a>
      </li>
    </ul>
    <div class="hds-pagination__item hds-pagination__next-button">
      <button
        type="button"
        class="hds-button hds-button--supplementary hds-button--theme-black hds-pagination__button-next"
      >
        <span class="hds-button__label">Next</span>
        <span aria-hidden="true" class="hds-icon hds-icon--angle-right"></span>
      </button>
    </div>
  </nav>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      